<template>
  <div class="border rounded-lg border-grey-300">
    <CardHeader
      v-if="title"
      :title="title"
      :subtitle="subtitle"
      :icon="icon"
      :accentColor="accentColor"
    />
    <div
      v-if="$slots.default"
      class="rounded-lg"
      :class="{
        'flex flex-col items-center': centerContent,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import CardHeader from './CardHeader'
export default {
  components: { CardHeader },
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    accentColor: {
      type: String,
      default: 'black',
    },
    centerContent: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
