<template>
  <div class="cursor-pointer max-w-1/2 flex-grow">
    <input
      :id="uniqueId"
      class="sr-only"
      type="radio"
      :name="uniqueId"
      :value="uniqueId"
      :checked="isSelected"
      @input="onInput"
    />
    <label
      class="border rounded py-10 pl-12 pr-4 flex items-center flex-wrap w-full cursor-pointer"
      :class="buttonClass"
      :for="uniqueId"
    >
      <Icon v-if="isSelected" iconClass="fas fa-check text-sm mr-10" />
      <p>
        {{ $t(`rewards.reward_selection.${type}_card`) }}
      </p>
      <p class="text-xs ml-4" :class="{ 'text-grey-500': !isSelected }">
        {{ $t(`rewards.reward_selection.wait_${type}`) }}
      </p>
    </label>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AutoRewardMethodSelector',
  props: {
    reward: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('rewards', ['selectedAutoReward']),
    isSelected() {
      return this.selectedAutoReward.id === this.reward.id
    },
    buttonClass() {
      return {
        'px-10': this.type === 'digital',
        'border-blue-500 bg-blue-500 text-white': this.isSelected,
        'border-grey-300': !this.isSelected,
      }
    },
    uniqueId() {
      return `${this.reward.name}-${this.type}`
    },
  },
  methods: {
    ...mapActions('rewards', ['selectAutoReward']),
    onInput() {
      return this.selectAutoReward({ reward: this.reward, type: this.type })
    },
  },
}
</script>

<style scoped lang="postcss">
:focus + label {
  @apply shadow-outline;
}
</style>
