import { mapGetters } from 'vuex'
import { CURRENCY_TYPES } from '@platform-shared/constants'
import constants from '@platform-shared/store/rewards/rewards.constants'

export default {
  data: () => ({ constants }),
  computed: {
    ...mapGetters('rewards', [
      'currencyType',
      'usesDollars',
      'usesPoints',
      'usesRewards',
    ]),
    prepareLanguagePath() {
      return this.usesRewards ? this.currencyType : 'non_rewards'
    },
  },
  methods: {
    formatCurrency(value, currencyTypeOverride) {
      const currencyType = currencyTypeOverride
        ? currencyTypeOverride
        : this.currencyType
      switch (currencyType) {
        case CURRENCY_TYPES.DOLLARS:
          return '$' + value
        case CURRENCY_TYPES.POINTS:
          return (value * 1).toLocaleString()
        default:
          return value
      }
    },
    formatTransaction(transactionType, amount) {
      const prefix = transactionType === constants.REWARD ? '+' : '-'
      return prefix + this.formatCurrency(Math.abs(amount))
    },
  },
}
