<template>
  <Card>
    <div
      class="flex justify-between rounded-t-lg items-center"
      :class="[`p-${padding}`, { 'cursor-pointer': !keepExpanded }]"
      @click="toggleExpanded"
    >
      <slot name="title" class="flex-1" />
      <Icon
        v-if="!keepExpanded"
        :iconClass="
          `fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'} mr-8`
        "
      />
    </div>
    <div v-if="isExpanded || keepExpanded" :class="`p-${padding}`">
      <slot />
    </div>
  </Card>
</template>

<script>
import { Card } from '@components/common/card/index'

export default {
  components: { Card },
  props: {
    defaultExpanded: {
      type: Boolean,
      default: false,
    },
    keepExpanded: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: [String, Number],
      default: '25',
    },
  },
  data() {
    return {
      isExpanded: this.defaultExpanded,
    }
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded || this.keepExpanded
    },
  },
}
</script>
