<template>
  <div class="w-full">
    <div class="p-20 flex items-center">
      <component
        :is="svg"
        v-if="svg"
        class="mr-10 h-18 w-18"
        :class="`text-${accentColor}-500`"
      />
      <h2 class="font-bold text-lg">{{ title }}</h2>
      <div v-if="$slots.default" class="flex-1">
        <slot />
      </div>
      <div v-else-if="subtitle" class="flex-1 flex justify-end">
        <p class="font-bold text-lg">{{ subtitle }}</p>
      </div>
    </div>
    <CardDivider />
  </div>
</template>

<script>
import * as icons from '@assets/svgs/index'
import CardDivider from './CardDivider'
import _get from 'lodash/get'
export default {
  components: {
    CardDivider,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    accentColor: {
      type: String,
      default: 'black',
    },
  },
  computed: {
    svg() {
      return _get(icons, this.icon, null)
    },
  },
}
</script>

<style></style>
