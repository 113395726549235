<template>
  <div class="flex-grow sm:max-w-1/2">
    <button
      v-if="count === 0"
      class="border rounded py-10 pl-12 pr-4 flex items-center flex-wrap w-full"
      :class="{
        'bg-grey-100 cursor-not-allowed': disabled,
        'cursor-pointer': !disabled,
      }"
      :disabled="disabled"
      @click="addToBasket"
    >
      <p class="font-semibold">
        {{ buttonCaption }}
      </p>
      <p v-if="!isMerchandise" class="text-xs text-grey-500 ml-4">
        {{ $t(`rewards.reward_selection.wait_${type}`) }}
      </p>
    </button>
    <div
      v-else
      class="flex items-center border-2 border-blue-500 rounded py-9 pl-12 pr-4 text-blue-500"
    >
      <Icon iconClass="fas fa-check text-sm" />
      <p class="ml-10 font-semibold">
        {{ buttonCaption }}
      </p>
      <div class="flex items-center justify-between mr-12 ml-auto">
        <button class="rounded-full" :disabled="disabled" @click="addToBasket">
          <Icon
            :title="$t('rewards.reward_selection.add_reward')"
            iconClass="fas fa-plus-circle p-1 rounded-full mx-5 bg-blue-500 text-white"
            :class="
              remainingMoney(healthActionCompletionId)
                ? 'opacity-100 cursor-pointer'
                : 'opacity-25'
            "
          />
        </button>
        <button class="rounded-full" @click="removeFromBasket">
          <Icon
            :title="$t('rewards.reward_selection.remove_reward')"
            iconClass="fas fa-minus-circle p-1 rounded-full mx-5 cursor-pointer bg-blue-500 text-white"
          />
        </button>
        <p class="ml-5">{{ count }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isMerchandise: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('rewards', ['basket', 'remainingMoney']),
    healthActionCompletionId() {
      return this.$route.query.hacId
    },
    count() {
      return this.basket
        .filter((item) => item.rewardId === this.reward.id)
        .reduce((acc, item) => acc + item.quantity, 0)
    },
    buttonCaption() {
      return this.$t(
        this.isMerchandise
          ? 'rewards.reward_selection.add_to_basket'
          : `rewards.reward_selection.${this.type}_card`
      )
    },
  },
  methods: {
    ...mapActions('rewards', ['addReward', 'removeReward']),
    addToBasket() {
      return this.addReward({
        reward: this.reward,
        healthActionCompletionId: this.healthActionCompletionId,
      })
    },
    removeFromBasket() {
      return this.removeReward({
        reward: this.reward,
        healthActionCompletionId: this.healthActionCompletionId,
      })
    },
  },
}
</script>
