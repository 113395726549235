<template>
  <ExpandableCard
    class="rounded-lg border-grey-300 border my-14"
    :class="{ 'bg-grey-100 text-grey-500': disabled && !reward.isInBasket }"
    padding="16"
    :keepExpanded="reward.isInBasket"
  >
    <template #title>
      <div class="flex items-center w-full">
        <img
          aria-hidden="true"
          :src="reward.imageUrl"
          class="object-cover w-60 h-40"
          :alt="reward.name"
        />
        <p
          v-if="!autoReward"
          class="text-base ml-20 flex-1 flex justify-between text-left"
        >
          <span>{{ `$${reward.faceValue} ${reward.name}` }}</span>
        </p>
        <p v-else class="text-base ml-20 flex-1 text-left">
          {{ `${reward.name}` }}
        </p>
      </div>
    </template>

    <div :id="compositeId">
      <component
        :is="autoReward ? 'legend' : 'p'"
        v-if="showSelectImperative"
        class="mb-10 text-sm font-sans-normal break-all"
      >
        {{ $t('rewards.reward_selection.select_card') }}
      </component>
      <div v-if="!autoReward" class="flex flex-wrap">
        <GiftCardMethodSelector
          v-if="reward.isGiftCard && reward.digital"
          class="max-md:mb-8 md:mr-10"
          type="digital"
          :reward="reward.digital"
          :disabled="disabled"
        />
        <GiftCardMethodSelector
          v-if="reward.physical"
          type="physical"
          :isMerchandise="reward.isMerchandise"
          :reward="reward.physical"
          :disabled="disabled"
        />
      </div>
      <fieldset v-if="autoReward" class="flex flex-wrap">
        <AutoRewardMethodSelector
          v-if="reward.digital"
          class="max-md:mb-8"
          type="digital"
          :class="{ 'md:mr-10': reward.physical }"
          :reward="reward.digital"
        />
        <AutoRewardMethodSelector
          v-if="reward.physical"
          type="physical"
          :reward="reward.physical"
        />
      </fieldset>
    </div>
  </ExpandableCard>
</template>
<script>
import GiftCardMethodSelector from './GiftCardMethodSelector'
import AutoRewardMethodSelector from './AutoRewardMethodSelector'
import _get from 'lodash/fp/get'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import { ExpandableCard } from '@components/common/card/index'

export default {
  components: {
    ExpandableCard,
    AutoRewardMethodSelector,
    GiftCardMethodSelector,
  },
  mixins: [rewardsCurrency],
  props: {
    reward: {
      type: Object,
      required: true,
    },
    autoReward: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    showSelectImperative() {
      return !_get('reward.isMerchandise', this)
    },
    compositeId() {
      return `${this.reward.name}`
    },
  },
}
</script>
