<template>
  <div class="w-full">
    <CardDivider />
    <div
      class="flex items-center px-20 py-20"
      :class="{ 'cursor-pointer': $listeners.click }"
    >
      <component :is="svg" v-if="svg" class="mr-12 h-18 w-18" />
      <div class="flex-1 truncate">
        <p class="font-semibold">{{ title }}</p>
        <p
          v-if="subtitle"
          class="inline-block font-semibold rounded-sm"
          :class="[subtitleColor, subtitleBackGroundColor, padding]"
        >
          {{ subtitle }}
        </p>
      </div>
      <component
        :is="caret"
        v-if="$listeners.click"
        class="transform -rotate-90 h-18 w-18"
      />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import * as icons from '@assets/svgs/index'
import CardDivider from './CardDivider'
import _get from 'lodash/get'

export default {
  components: {
    CardDivider,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleColor: {
      type: String,
      default: 'text-black',
    },
    subtitleBackGroundColor: {
      type: String,
      default: '',
    },
    padding: {
      type: String,
      default: '',
    },
  },
  computed: {
    caret() {
      return icons.Caret
    },
    svg() {
      return _get(icons, this.icon, null)
    },
  },
}
</script>

<style></style>
